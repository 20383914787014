import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {useNavigate} from 'react-router-dom';
import '../../../styles/my_trips.css';
import Chip from "./components/chip";
import AllTrips from "./components/all_trip";

const MyTripsPage = () => {
    const [selectedChip, setSelectedChip] = React.useState(0);
    return (
        <div className="my-trips-page">
          <div className="my-trips-header">
            <p>My Trips</p>
            <div className="my-trips-header-icon">
                <i className="fa fa-bicycle"></i>
                </div>
                </div>  
        <div className="my-trips-content">
        {/* <div className="my-trips-chips">
            <Chip label="All" selected={selectedChip === 0} onClick={() => setSelectedChip(0)} />
            <Chip label="Pending" selected={selectedChip === 1} onClick={() => setSelectedChip(1)} />
            <Chip label="Completed" selected={selectedChip === 2} onClick={() => setSelectedChip(2)} />
            <Chip label="Cancelled" selected={selectedChip === 3} onClick={() => setSelectedChip(3)} />
        </div> */}
        <AllTrips selectedChip={selectedChip} />
        </div>
        </div>
    );
}


export default MyTripsPage;