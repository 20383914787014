import React from "react";
import "../../../styles/buyfrom_item.css";

const BuyFromItem = ({ title, image }) => {

    return (
        <div className="buy-from-item">
            <img src={image} alt="store" />
            <div className="title">{title}</div>
        </div>
    );
}

export default BuyFromItem;