import React  from "react";

const ShimmerEffect = () => {
    return (
        <div className="shimmer-effect">
            <div className="shimmer-effect-content">
                <div className="shimmer-effect-header">
                    <div className="shimmer-effect-header-text"></div>
                </div>
                <div className="shimmer-effect-body">
                    <div className="shimmer-effect-body-text"></div>
                    <div className="shimmer-effect-body-text"></div>
                    <div className="shimmer-effect-body-text"></div>
                </div>
            </div>
        </div>
    );
}


export default ShimmerEffect;