import React from "react";
import { useEffect } from "react";
import { fetchMyTrips } from "../../../../providers/place_trip_provider";
const AllTrips = ({ selectedChip }) => {
    const [trips, setTrips] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const [key, setKey] = React.useState(Date.now());

    useEffect(() => {
        setIsLoading(true);
        fetchMyTrips()
            .then((data) => {
                if (data && data.length > 0) {
                    setTrips(data);
                    setError(null);
                } else {
                    setError("No trips found");
                    setTrips([]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || "An error occurred while fetching trips.");
                setTrips([]);
            });
    }, [key]); // Only re-run effect when key changes


    const formatDateAndTime = (time) => {
        const date = new Date(time / 1000);
        return date.toLocaleString();
    }

    return (
        <div className="all-trips">
            {trips.map((trip, index) => {
                return (
                    <div className="trip" key={index} onClick={() => setOpen(true)}>
                        <div className="trip-title_and_time">
                            <div className="collection">{trip["collection type"]}</div>
                            <div className="time">{formatDateAndTime(trip.time)}</div>
                        </div>
                        <div className="trip-status">
                            <div className="status">{trip.Status.firstLetterUpperCase()}</div>
                        </div>
                    </div>
                );
            })}
            {isLoading && <div className="loading">Loading...</div>}
            {error && <div className="error">{error}</div>}
            {open && <div className="app-download-alert">
                <div className="title">Download our mobile app</div>
                <div className="description">To track your trip and get real-time updates, download our mobile app, available for both Android and iOS devices.</div>
                <div className="buttons">
                    <div className="button">Download for Android</div>
                    <div className="button">Download for iOS</div>
                </div>
                <div className="close" onClick={() => setOpen(false)}>Close</div>
            </div>}
        </div>
    );
}


export default AllTrips;



String.prototype.firstLetterUpperCase = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}