import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {useNavigate} from 'react-router-dom';
import { useAuth } from "../../../providers/auth_provider";

const AppBar = () => {
    const navigate = useNavigate();
    const {signOut} = useAuth();
 
    const logoutFunc = () => {
        signOut();
        navigate('/');
    }
    return (
        <div className="app-bar">
            <div className="app-bar-title">
                Wyn Deliveries
            </div>
            <div className="app-bar-menu">
                
                <div className="menu-item" onClick={() => navigate('/my_trips')}>
                    <div className="menu-item-icon" >
                        <i className="fa fa-bicycle"></i>
                    </div>
                    <div className="menu-item-text">
                        <p>My Trips</p>
                    </div>
                </div>
                <div className="menu-item">
                    <div className="menu-item-icon">
                        <i className="fa fa-user"></i>
                    </div>
                    <div className="menu-item-text">
                        <p>Profile</p>
                    </div>
                </div>
                
                <div className="menu-item" onClick={() => navigate('/login')}>
                    <div className="menu-item-icon">
                        <i className="fa fa-sign-out"></i>
                    </div>
                    <div className="menu-item-text">
                        <p>Logout</p>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default AppBar;