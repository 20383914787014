import React from 'react'
import { useEffect, useState } from 'react'
import CustomButton from '../../components/custom_button'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/auth_provider'

const ageRanges = [
    '15-20',
    "21-25",
    "26-30",
    "31-35",
    "36-40",
    "41 and above"
    ]

    const genders = [
        'Male',
        "Female",
        "Prefer not to say"
        ]
const SignUpPage = () => {
  //username
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  //phone number
  const [phoneNumber, setPhoneNumber] = useState('')
  //home address
  const [homeAddress, setHomeAddress] = useState('')
  //age range
  const [ageRange, setAgeRange] = useState('')
  //gender
  const [gender, setGender] = useState('')
  const [password, setPassword] = useState('')
  //confirm password
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { registerUser } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('')
      }, 3000)
    }
  }, [error])
  const handleSignUp = () => {
    if (
      !username ||
      !email ||
      !phoneNumber ||
      !homeAddress ||
      !ageRange ||
      !gender ||
      !password ||
      !confirmPassword
    ) {
      setError('Please fill in all fields')
    } else if (password !== confirmPassword) {
      setError('Passwords do not match')
    } else {
      signUp()
    }
  }
  const signUp = async () => {
    try {
      setLoading(true);
      await registerUser(username, email, phoneNumber, homeAddress, ageRange, gender, password);
      navigate('/home_page'); // Redirect to home_page after successful login
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className='login_page'>
      <div className='login_page_content'>
        <p className='login_title'>Hello There! Let's get started</p>
        <div className='login_form'>
          <div className='login_input'>
            <label>Username</label>
            <input
              type='text'
              placeholder='Enter your username'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className='login_input'>
            <label>Email</label>
            <input
              type='email'
              placeholder='Enter your email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className='login_input'>
            <label>Phone Number</label>
            <input
              type='text'
              placeholder='Enter your phone number'
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className='login_input'>
            <label>Home Address</label>
            <input
              type='text'
              placeholder='Enter your home address'
              value={homeAddress}
              onChange={e => setHomeAddress(e.target.value)}
            />
          </div>
            <div className='login_input'>
                <label>Age Range</label>
                <select
                value={ageRange}
                onChange={e => setAgeRange(e.target.value)}
                >
                <option value=''>Select an age range</option>
                {ageRanges.map((age, index) => (
                    <option key={index} value={age}>
                    {age}
                    </option>
                ))}
                </select>
                </div>
                <div className='login_input'>
                    <label>Male</label>
                    <select
                value={gender}
                onChange={e => setGender(e.target.value)}
                >
                <option value=''>Select Gender</option>
                {genders.map((gender, index) => (
                    <option key={index} value={gender}>
                    {gender}
                    </option>
                ))}
                </select>
                    </div>
                    <div className='login_input'>
            <label>Phone Number</label>
            <input
              type='text'
              placeholder='Enter your password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className='login_input'>
            <label>Phone Number</label>
            <input
              type='text'
              placeholder='Confirm your password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          {error && <p className="error_message">{error}</p>}
          <CustomButton text="Signup" onClick={handleSignUp} isDisabled={false} />
          <p className="signup_link" onClick={() => navigate("/login")}>
                       Already Have an account? <span>Sign In</span>
                    </p>
                    <p className="terms_and_conditions">
                        By signing up, you agree to our <span>Terms and Conditions</span>
                    </p>

                  

                   
                        
        </div>
      </div>
    </div>
  )
}

export default SignUpPage


// Import the functions you need from the SDKs you need
