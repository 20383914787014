import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AuthProvider } from './providers/auth_provider';
import { AppDataProvider } from './providers/direction_and_price_provider';
import PlaceTripProvider from './providers/place_trip_provider';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDIWulxTjr1PMQpYe288jN4WFxlToFK07E",
  authDomain: "gobike-de6dd.firebaseapp.com",
  databaseURL: "https://gobike-de6dd-default-rtdb.firebaseio.com",
  projectId: "gobike-de6dd",
  storageBucket: "gobike-de6dd.appspot.com",
  messagingSenderId: "597168621592",
  appId: "1:597168621592:web:90af6fc34bffd9ee2ddb6d",
  measurementId: "G-3CV2JKQ4BV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
    <AppDataProvider>
      <PlaceTripProvider>
    <App />
    </PlaceTripProvider>
    </AppDataProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
