import React from "react";
import "../styles/custom_button.css";

const CustomButton = ({ text, onClick, isDisabled }) => {
    return (
        <button
            className="custom_button"
            onClick={onClick}
            disabled={isDisabled}
        >
            {text}
        </button>
    );
}


export default CustomButton;