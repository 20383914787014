import React from 'react';

// Define the OnboardingStuffs class
class OnboardingStuffs {
  constructor(img, title, description) {
    this.img = img;
    this.title = title;
    this.description = description;
  }
}

// Define your data
const stuffs = [
  new OnboardingStuffs(
    'src/assets/images/delivery_guy.png',
    'Fast Pickup & Delivery',
    'At Wyn Deliveries, we offer 24 hour delivery service. No matter what size of freight you have, we can make sure your delivery is on time and in excellent condition. We are committed to being the best in the business.'
  ),
  new OnboardingStuffs(
    'src/assets/images/discount.png',
    'Invite Friends & Get Discounts',
    'Invite your friends & family to join us and get 50% off on every ride.'
  ),
  new OnboardingStuffs(
    'src/assets/images/tracking.png',
    'Track Delivery',
    'Track delivery, get every detail on an ongoing delivery, In-App call & Chats with riders'
  ),
];


export default OnboardingStuffs