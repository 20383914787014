import React from "react";
import { useAuth } from "../../providers/auth_provider";
import CircleLoader from "../../components/circle_loader";
import HomeMapPage from "./components/home_ap_page";
import "../../styles/home_page.css"
import FirstSheet from "./components/first_sheet";
import AppBar from "./components/app_bar";


const HomePage = () => {

    return (
        <div className="home-page">
           <HomeMapPage />
           <AppBar />
           <FirstSheet />
        </div>
    );
};

export default HomePage;