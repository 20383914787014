import './App.css';
import SplashScreen from './views/auth/splash_screen';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import UserType from './views/auth/user_type';
import LoginPage from './views/auth/login_page';
import SignUpPage from './views/auth/sign_up_page';
import HomePage from './views/main/home_page';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import SendPackageDetails from './views/main/components/send_package_page';
import SelectLocation from './views/main/components/select_locatiom';
import SummaryAndPayment from './views/main/components/summary_and_payment';
import OrderSuccessMessage from './views/main/components/order_placed_message';
import MyTripsPage from './views/main/trips/my_trips_page';
import { Helmet } from 'react-helmet-async';
import ForgotPassword from './views/auth/forgot_password';



function App() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);
  return (
    <div className="App">

      <Router>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/user_type" element={<UserType />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/home_page" element={<HomePage />} />
          <Route path="/splash_screen" element={<SplashScreen />} />
          <Route path="/send-package" element={<SendPackageDetails />} />
          <Route path="/select_location" element={<SelectLocation />} />
          <Route path="/summary_and_payment" element={<SummaryAndPayment />} />
          <Route path="/order-successful" element={<OrderSuccessMessage />} />
          <Route path="/my_trips" element={<MyTripsPage />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
