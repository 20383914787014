
import React, { useState, useEffect } from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const HomeMapPage = () => {
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error getting current location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAIfk7uZ3sIkgA1x6uuzNQy21VS5PoM6HM" }}
                defaultCenter={{ lat: 59.95, lng: 30.33 }}
                defaultZoom={15}
                center={currentLocation} // Set the center of the map to currentLocation
            >
                <AnyReactComponent
                    lat={currentLocation?.lat}
                    lng={currentLocation?.lng}
                    text="My Marker"
                />
                
            </GoogleMapReact>
        </div>
    );
}

export default HomeMapPage;