import React from "react";
import supermarket from "../../../assets/images/supermarket.png";
import mrBiggs from "../../../assets/images/mrbiggs.png";
import dominos from "../../../assets/images/pizza.png";
import coldStone from "../../../assets/images/coldstone.png";
import BuyFromItem from "./buy_from_item";

const BuyFromStoreBox = ({ userData }) => {
    return (
        <div className="send-package-box">
        <div className="send-package-box-header">
            <div className="title">Buy from a store</div>
            <div className="subtitle">Some of the stores you can buy from</div>
            <div className="send-package-box-body-buy-from">
                <BuyFromItem title="Supermarket" image={supermarket} />
                <BuyFromItem title="Mr Biggs" image={mrBiggs} />
                <BuyFromItem title="Domino's" image={dominos} />
                <BuyFromItem title="Cold Stone" image={coldStone} />
                </div>
            </div>
        </div>
    );
}

export default BuyFromStoreBox;