import React from "react";
import CustomButton from "../../../components/custom_button";
import Lottie from "lottie-react";
import checkMark from "../../../assets/animations/check_mark.json";
import useLottie from "lottie-react";
import { useNavigate } from "react-router-dom";

const OrderSuccessMessage = () => {
    const options = {
        loop: true,
        autoplay: true,
        animationData: checkMark,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    const {container, animation} = useLottie(options);
    const navigate = useNavigate();
    const handleButtonClick = () => {
        //clear all the routes stack
        navigate('/home_page', {replace: true});
    }
  return (
    <div className="order-success-message-container">
        <Lottie animationData={checkMark} loop={false} autoplay={true} style={{width: '300px', height: '300px', margin: 'auto'}} />
      <h1>Trip Placed Successfully</h1>
      <p>Your trip has been placed successfully. You will be notified when a driver accepts your trip. Thank you for choosing us.</p>
      <CustomButton text={"Back to Home"} onClick={() => handleButtonClick()} isDisabled={false} />
    </div>
  );
}

export default OrderSuccessMessage;