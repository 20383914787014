import React from "react";
import "../../styles/login_page.css";
import loginLogo from "../../assets/images/login_logo.png";
import {useEffect, useState} from "react";
import CustomButton from "../../components/custom_button";
import {useHistory, useNavigate} from "react-router-dom";
import { useAuth } from "../../providers/auth_provider";

const LoginPage = () => {
    const { loginUser } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    }, [error]);
    const handleLogin = async () => {
        if (!email || !password) {
            setError("Please fill in all fields");
        } else {
            try {
                setLoading(true);
                await loginUser(email, password);
                navigate('/home_page'); // Redirect to home_page after successful login
              } catch (error) {
                setError(error.message);
              } finally {
                setLoading(false);
              }
        }
    }
    return (
        <div className="login_page">
            <div className="login_page_content">
                <img src={loginLogo} alt="login logo" className="login_logo" />
                <p className="login_title">Great to see you again!</p>
              
                <div className="login_form">
                    <div className="login_input">
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login_input">
                        <label>Password</label>
                        <input
                            type="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <p
                    onClick={() => navigate("/forgot_password")}
                     className="forgot_password">Forgot password?</p>
                    {error && <p className="error_message">{error}</p>}
                    <CustomButton text={loading ? 'Logging in...' : 'Login'} onClick={handleLogin} isDisabled={loading} />
                    <p className="signup_link" onClick={() => navigate("/signup")}>
                        Don't have an account? <span>Sign up</span>
                    </p>
                    <p className="terms_and_conditions">
                        By signing in, you agree to our <span>Terms and Conditions</span>
                    </p>
                    </div>

                
                
            </div>
            
        </div>
    );
    }
export default LoginPage;