import React from 'react';
const DialogContainer = ({ onClose }) => {
    return (
        <div className="dialog_container">
            <div className="dialog_content">
                <p className="dialog_title">Rider Signup</p>
                <p className="dialog_text">
                    Rider signup is not available on web yet. Please download the mobile app to signup as a rider.
                </p>
                <p className="dialog_link">Download on App Store : <a href="https://apps.apple.com/us/app/your-app-name/id1234567890">Wyn Rider</a></p>
                <p className="dialog_link">Download on Play Store : <a href="https://play.google.com/store/apps/details?id=com.yourappname">Wyn Rider</a></p>
                <button className="dialog_button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
}

export default DialogContainer;