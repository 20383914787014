import React  from "react";
import PackageBoxItem from "./package_box_item";
import organicProduct from "../../../assets/images/organic-product.png";
import clothesRack from "../../../assets/images/clothes-rack.png";
import folder from "../../../assets/images/folder.png";
import electronics from "../../../assets/images/electronics.png";
import { useNavigate } from "react-router-dom";

const SendPackageBox = ({ userData }) => {
    const navigate = useNavigate();

    return (
        <div className="send-package-box">
            <div className="send-package-box-header">
                <div className="title">Send a package</div>
                <div className="subtitle">Select the type of package you want to send</div>
                </div>
            <div className="send-package-box-body">
                <PackageBoxItem title= "Food" image={organicProduct} onClick={() => {
                    var object = {
                        title: "Food",
                        image: organicProduct,
                        dropDownItems: [
                            "Vegetables",
                            "Fruits",
                            "milk, yoghurt, cheese",
                            "Meat",
                            "Baked Goods",
                            "Nuts and seeds",
                            "Herbs and Spices",
                            "Seafood",
                            "Dairy foods",
                            "Garnishes",
                            "Eggs",
                        ],
                    }
                    navigate("/send-package", { state: object });
                }} />
                <PackageBoxItem title= "Clothing" image={clothesRack} onClick={() => {
                    var object = {
                        title: "Clothing",
                        image: clothesRack,
                        dropDownItems: [
                            "Fabrics",
                            "Silk",
                            "Linen Fabric.",
                            "Leather Material",
                            "Georgette Fabric",
                            "Chiffon Fabric.",
                            "Nylon Fabric.",
                            "All",
                        ],
                    }
                    navigate("/send-package", { state: object });
                }} />
                <PackageBoxItem title= "Documents" image={folder} onClick={() => {
                    var object = {
                        title: "Documents",
                        image: folder,
                        dropDownItems: [
                            "Mail.",
                            "Business Letters.",
                            "Business Reports",
                            "Transactional Documents.",
                            "Financial Reports and Documents",
                            "None",
                            "All ",
                        ],
                    }
                    navigate("/send-package", { state: object });

                }} />
                <PackageBoxItem title= "Electronics" image={electronics} onClick={() => {
                    var object = {
                        title: "Electronics",
                        image: electronics,
                        dropDownItems: [
                            "Digital electronics.",
                            "Analogue electronics.",
                            "Microelectronics",
                            "Circuit design.",
                            "Integrated circuits.",
                            "Power electronics.",
                            "Optoelectronics.",
                            "Semiconductor Devices",
                            "Telecommunications",
                            "None",
                            "All"
                        ],
                    }
                    navigate("/send-package", { state: object });
                }} />
             </div>
        </div>
    );
}

export default SendPackageBox;