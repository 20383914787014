import React from 'react'
import '../../../styles/first_sheet_content.css'
import SendPackageBox from './send_package_box'
import { useEffect, useState } from 'react'
import axios from 'axios'
import BuyFromStoreBox from './buyfromastore'
import AdsComponent from '../../../components/ads_component'

const FirstSheetContent = ({ userData }) => {
  const [userLocation, setUserLocation] = useState('')

  useEffect(() => {
    const fetchUserLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async position => {
            const { latitude, longitude } = position.coords
            try {
              const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCCCX9g9XS_EHt4ln1AcYyUUTGEvUoy-VI`
              )

              const addressComponents =
                response.data.results[0].address_components
              const country = addressComponents.find(component =>
                component.types.includes('country')
              )
              const city = addressComponents.find(
                component =>
                  component.types.includes('locality') ||
                  component.types.includes('postal_town')
              )
              const state = addressComponents.find(component =>
                component.types.includes('administrative_area_level_1')
              )

              // Construct the formatted location string
              let formattedLocation = ''
              if (city && state) {
                formattedLocation = `${city.long_name}, ${state.long_name}`
                if (country) {
                  formattedLocation += `, ${country.long_name}`
                }
              }

              setUserLocation(formattedLocation)
            } catch (error) {
              console.error('Error getting user location:', error)
            }
          },
          error => {
            console.error('Error getting user location:', error)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }

    fetchUserLocation()
  }, [])
  return (
    <div className='first-sheet-content'>
      <AdsComponent dataAdSlot='4949981253'/>
      <div className='user-location'>
        <div className='location-icon'></div>
        <div className='location-text'>{userLocation}</div>
      </div>
      <div className='divider'></div>
      <div className='welcome-box'>
        <div className='welcome-text'>
          <p className='header'>Hi, {userData.username}</p>
          <div className='body'>
            Instant delivery to your doorstep, anytime, anywhere
          </div>
        </div>
        <div className='how-to-video'>
          <div className='play_icon'></div>
        </div>
      </div>
      <SendPackageBox userData={userData} />
      <BuyFromStoreBox userData={userData} />
    </div>
  )
}

export default FirstSheetContent
