import React from "react";
import { useEffect, useState } from "react";
import CustomButton from "../../components/custom_button";
import { useAuth } from "../../providers/auth_provider";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [validation, setValidation] = useState("");
    const { sendPasswordRestLink } = useAuth();
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("");
            }, 3000);
        }
        if (validation) {
            setTimeout(() => {
                setValidation("");
            }, 3000);
        }
    }
    , [error, validation]);
    const handleForgotPassword = async () => {
        if (!email) {
            setError("Please fill in all fields");
        } else {
            try {
                setLoading(true);
                // Call the forgotPassword function from the auth provider
                await sendPasswordRestLink(email);
                setValidation("Password reset link sent successfully");
                setEmail("");
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    }
    return (
        <div className="login_page">
        <div className="login_page_content">
            <p className="login_title">Forgot Password</p>
            <p className="login_subtitle">Enter your email to reset your password</p>
            <div className="login_form">
                <div className="login_input">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {error && <p className="error_message">{error}</p>}
                {validation && <p className="validation_message"
                style={{color: 'green'}}
                >{validation}</p>}
                <CustomButton text={loading ? 'Sending Link...' : 'Send Link'} onClick={handleForgotPassword} isDisabled={loading} />
                </div>
                </div>
                </div>
    );
    }

export default ForgotPassword;