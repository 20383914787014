import React, { useContext, useEffect, useState } from 'react'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  serverTimestamp
} from 'firebase/firestore'
import { auth } from '../config/firebase'

const PlaceTripContext = React.createContext()

export const usePlaceTrip = () => {
  return useContext(PlaceTripContext)
}

export const saveSendTypeToDb = async ({
  description,
  collection,
  sendersusername,
  pending,
  dropOffnumber,
  pickupNumber,
  pickup,
  dropOff,
  deliveryId,
  estimatedTime,
  transportationType,
  paymentMethod,
  lat,
  long,
  fee,
  status,
  encodedPoints
}) => {
  try {
    const user = auth.currentUser
    const now = new Date()
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const db = getFirestore()
    const updateSends = deliveryId
      ? doc(db, 'Sends', deliveryId)
      : doc(db, 'Sends')
    const start = Date.now()
    const nowDate = start + performance.now()
    const microsecondsSinceEpoch = Math.floor(nowDate * 1000)
    await setDoc(updateSends, {
      uid: user.uid,
      username: sendersusername,
      email: user.email,
      'Pickup Number': pickupNumber,
      'Dropoff Number': dropOffnumber,
      img: null,
      'collection type': collection,
      Description: description,
      'Promo Code': null,
      Accepted: false,
      'Pickup Location': pickup,
      deliveryId: deliveryId,
      estimatedTime: estimatedTime,
      'Dropoff Location': dropOff,
      'Payment Method': paymentMethod,
      fee: parseInt(fee),
      'Vehicle Type': transportationType,
      'Order Placed': true,
      'Reached Pickup Station': false,
      'On the way to drop off': false,
      'Reached drop off station': false,
      'Package delivered': false,
      driverspercent: null,
      date: date,
      lat: lat,
      long: long,
      Status: status,
      'Encoded Points': encodedPoints,
      createdOn: serverTimestamp(),
      time: microsecondsSinceEpoch
    })
  } catch (error) {
    console.error('Error saving send to db:', error)
    return { error: error.message }
  }
}

export const PlaceTripProvider = ({ children }) => {
  //fetch vehichles from firestore
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [myTrips, setMyTrips] = useState([])

  const fetchVehicles = async () => {
    const db = getFirestore()
    const vehiclesRef = collection(db, 'Vehicle Types')
    const q = query(vehiclesRef)
    const querySnapshot = await getDocs(q)
    const vehicles = querySnapshot.docs.map(doc => doc.data())
    console.log("vehicles", vehicles)
    setVehicles(vehicles)
  }
  const fetchMyTrips = async () => {
    const db = getFirestore();
    const user = auth.currentUser;
    if (!user) {
      // Handle the case where the user is not authenticated
      console.log("user not authenticated")
      return;
    }
    const sendsRef = collection(db, 'Sends');
    const q = query(sendsRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    const myTrips = querySnapshot.docs.map(doc => doc.data());
    console.log("myTrips", myTrips)
    setMyTrips(myTrips);
  };
  useEffect(() => {
    fetchVehicles()
    
  }, [])

  const selectVehicle = vehicle => {
    setSelectedVehicle(vehicle)
  }

  return (
    <PlaceTripContext.Provider
      value={{ vehicles, selectedVehicle, selectVehicle, myTrips }}
    >
      {children}
    </PlaceTripContext.Provider>
  )
}

export default PlaceTripProvider

//how to use the provider in a component

// import { usePlaceTrip } from '../../../providers/place_trip_provider'

// const { vehicles, selectedVehicle, selectVehicle, myTrips } = usePlaceTrip()

export const fetchMyTrips = async () => {
  const db = getFirestore();
  const user = auth.currentUser;
  if (!user) {
    // Handle the case where the user is not authenticated
    console.log("user not authenticated")
    return;
  }
  const sendsRef = collection(db, 'Sends');
  const q = query(sendsRef, where('uid', '==', user.uid));
  const querySnapshot = await getDocs(q);
  const myTrips = querySnapshot.docs.map(doc => doc.data());
  console.log("myTrips", myTrips)
  return myTrips;
};