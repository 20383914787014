import React, { createContext, useContext, useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios'
const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

const initialState = {
  pickupLocationAddress: {
    longitude: null,
    latitude: null,
    placeName: '',
  },
  directionsDetails: null,
  placePredictionsList: [],
};

export const AppDataProvider = ({ children }) => {
  const [appData, setAppData] = useState(initialState);
  const mapKey = "AIzaSyAIfk7uZ3sIkgA1x6uuzNQy21VS5PoM6HM";

  const updatePickupLocationAddress = (userpickupAddress) => {
    setAppData((prevData) => ({
      ...prevData,
      pickupLocationAddress: userpickupAddress,
    }));
  };

  const updateDirectionsDetails = (details) => {
    setAppData((prevData) => ({
      ...prevData,
      directionsDetails: details,
    }));
  };

  const calculatePrice = (durationValue, perKm, baseFare) => {
    let timeFare = (durationValue / 60) * 0.1;
    let distanceFare =
      (durationValue / 1000) * 0.1 + 230.0 / 1000; // Adding 220 per km
    let totalAmount = timeFare + distanceFare;
    let totalLocalAmount = totalAmount * 600.00;
  
    // Ensure that the totalLocalAmount is never below 1000
    let finalAmount = Math.trunc(totalLocalAmount);
    if (finalAmount < baseFare) {
      finalAmount = baseFare;
    }
  
    return finalAmount;
  }

  const findUsersPlacesDebounced = debounce(findUsersPlaces, 300); // Debounce with 300ms delay

  async function findUsersPlaces(placeName) {
    if (placeName.length > 1) {
      try {
        const response = await axios.get(
          `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${placeName}&key=${mapKey}&sessiontoken=1234567890&components=country:ng`
        );
        const placePredictionsList = response.data.predictions;
        setAppData((prevData) => ({
          ...prevData,
          placePredictionsList,
        }));
      } catch (error) {
        console.error('Error finding places:', error);
      }
    }
  }
  //clear the place predictions list
  const clearPlacePredictionsList = () => {
    setAppData((prevData) => ({
      ...prevData,
      placePredictionsList: [],
    }));
  }

  const obtainPlaceRoute = async (origin, destination) => {
    try {
      const response = await axios.get(
        `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/directions/json?origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&key=${mapKey}`
      );
      const directionsDetails = response.data.routes[0];
      console.log('directionsDetails', directionsDetails);
      updateDirectionsDetails(directionsDetails);
      return directionsDetails;
    } catch (error) {
      console.error('Error finding directions:', error);
    }
  }
  //get place latitude and longitude
  const getPlaceLatLong = async (placeId, retryCount = 0) => {
    try {
        const response = await axios.get(
            `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${mapKey}`
        );
        const placeLatLong = response.data.result.geometry.location;
        return placeLatLong;
    } catch (error) {
       if (error.response && error.response.status === 429 && retryCount < 3) {
            // Retry after a delay
            setTimeout(() => {
                getPlaceLatLong(placeId, retryCount + 1);
            }, 1000 * (retryCount + 1)); // Exponential backoff: 1s, 2s, 3s
        }
    }
  }
  return (
    <AppDataContext.Provider
      value={{
        appData,
        updatePickupLocationAddress,
        updateDirectionsDetails,
        calculatePrice,
        findUsersPlaces,
        clearPlacePredictionsList,
        obtainPlaceRoute,
        getPlaceLatLong
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
