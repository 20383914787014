import React from "react";
import "../../../styles/first_sheet.css"
import { useAuth } from "../../../providers/auth_provider";
import ShimmerEffect from "../../../components/shimmer_effect";
import FirstSheetContent from "./first_sheet_context";


const FirstSheet = () => {
    const { currentUser, userData, signOut } = useAuth();
    return (
        <div className="first-sheet">
            {userData ? (
                <FirstSheetContent userData={userData} />
              
            ) : (
                <ShimmerEffect />
            )}
      
        </div>
    );
}

export default FirstSheet;