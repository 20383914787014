import React from "react";

const PackageBoxItem = ({ title, image, onClick }) => {
    return (
        <div className="package-box-item" onClick={onClick}>
        <div className="package-box-circle">
            <img src={image} alt="package" className="package-box-image" />
           </div>
           <div className="title">{title}</div>
        
        </div>
    );
}

export default PackageBoxItem;