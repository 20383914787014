import React, { useState } from "react";
import OnboardingStuffs from "../../models/splash_screen_model";
import firstImage from "../../assets/images/delivery_guy.png";
import secondImage from "../../assets/images/discount.png";
import thirdImage from "../../assets/images/tracking.png";
import { useHistory, useNavigate } from "react-router-dom";

const SlideIndicator = ({ totalSlides, currentIndex }) => {
    const dots = [];
    for (let i = 0; i < totalSlides; i++) {
      dots.push(
        <span
          key={i}
          className={i === currentIndex ? "dot active" : "dot"}
         
        ></span>
      );
    }
    return <div className="indicator">{dots}</div>;
  }

const SplashScreen = () => {
  const history = useNavigate()
  // Define your data
  const stuffs = [
    new OnboardingStuffs(
      firstImage,
      "Fast Pickup & Delivery",
      "At Wyn Deliveries, we offer 24 hour delivery service. No matter what size of freight you have, we can make sure your delivery is on time and in excellent condition. We are committed to being the best in the business."
    ),
    new OnboardingStuffs(
      secondImage,
      "Invite Friends & Get Discounts",
      "Invite your friends & family to join us and get 50% off on every ride."
    ),
    new OnboardingStuffs(
      thirdImage,
      "Track Delivery",
      "Track delivery, get every detail on an ongoing delivery, In-App call & Chats with riders"
    ),
  ];

  // State to manage current index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle Next button click
  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  // Function to handle Continue button click
  const handleContinue = () => {
    // Redirect to the next page
    history("user_type");
    
  };

  return (
    <div className="splash_screen">
      <div className="slide">
        <img
          src={stuffs[currentIndex].img}
          alt={stuffs[currentIndex].title}
        />
        <h2>{stuffs[currentIndex].title}</h2>
        <p>{stuffs[currentIndex].description}</p>
      </div>
      <SlideIndicator totalSlides={stuffs.length} currentIndex={currentIndex} />
      <div>
        {currentIndex > 0 && (
          <button onClick={() => setCurrentIndex((prevIndex) => prevIndex - 1)}>
            Previous
          </button>
        )}
        {currentIndex < stuffs.length - 1 ? (
          <button onClick={handleNext}>Next</button>
        ) : (
          <button className="continue_button" onClick={handleContinue}>
            Get Started
          </button>
        )}
      </div>
    </div>
  );
};

export default SplashScreen;
