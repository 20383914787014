import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import CustomButton from '../../../components/custom_button'
import { useAppData } from '../../../providers/direction_and_price_provider'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { useAuth } from '../../../providers/auth_provider'

const SelectLocation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    appData,
    updatePickupLocationAddress,
    findUsersPlaces,
    clearPlacePredictionsList,
    getPlaceLatLong,
    obtainPlaceRoute,
  } = useAppData()
  const { fetchCurrentUserData } = useAuth()

  const [pickupLocation, setPickupLocation] = useState('')
  const [dropoffLocation, setDropoffLocation] = useState('')
  const [pickupPlaceName, setPickupPlaceName] = useState('')
  const [dropoffPlaceName, setDropoffPlaceName] = useState('')
  const [activeInput, setActiveInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)


  const handlePlaceNameChange = e => {
    const newPlaceName = e.target.value
    if (activeInput === 'pickup') {
      setPickupPlaceName(newPlaceName)
      findUsersPlaces(newPlaceName)
    } else if (activeInput === 'dropoff') {
      setDropoffPlaceName(newPlaceName)
      findUsersPlaces(newPlaceName)
    }
  }

  const handlePredictionClick = async placePrediction => {
    const placeName = placePrediction.description
    var placeLatLong = await getPlaceLatLong(placePrediction.place_id)
    console.log('placeLatLong', placeLatLong)
    if (activeInput === 'pickup') {
      setPickupPlaceName({
        placeName,
        latitude: placeLatLong.lat,
        longitude: placeLatLong.lng
      })
      setPickupLocation(placePrediction)
    } else if (activeInput === 'dropoff') {
      setDropoffPlaceName({
        placeName,
        latitude: placeLatLong.lat,
        longitude: placeLatLong.lng
      })
      setDropoffLocation(placePrediction)
    }
    clearPlacePredictionsList()
  }

  const handleInputFocus = input => {
    setActiveInput(input)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    var stateData = location.state
    var currentUserData = await fetchCurrentUserData()
    if (pickupLocation && dropoffLocation) {
      var origin = { latitude: pickupPlaceName.latitude, longitude: pickupPlaceName.longitude }
      var destination = { latitude: dropoffPlaceName.latitude, longitude: dropoffPlaceName.longitude }
      var data = await obtainPlaceRoute(origin, destination)
      navigate('/summary_and_payment', {
        state: {
          data,
          packageType: stateData.packageType,
          senderNumber: stateData.senderNumber,
          receiverNumber: stateData.receiverNumber,
          image: stateData.image,
          description: stateData.description,
          pickupPlaceName,
          dropoffPlaceName,
          currentUserData
        }
      })
      setIsLoading(false)
    } else {
      setIsLoading(false)
      alert('Please select both pickup and dropoff location')
    }
  }
  
  return (
    <div className='send-package-detail-page'>
      <div className='send-package-detail-box'>
        <div className='title_and_value'>
          <div className='title'>Select Pickup and Dropoff location</div>
          <div className='divider'></div>
        </div>
        <div className='title_and_value'>
          <div className='title'>Pickup location</div>
          <input
            type='text'
            placeholder='Enter pickup location'
            value={pickupPlaceName.placeName}
            onChange={handlePlaceNameChange}
            onFocus={() => handleInputFocus('pickup')}
          />
          {activeInput === 'pickup' && appData.placePredictionsList.length > 0 && (
            <div className='place-predictions'>
              {appData.placePredictionsList.map(placePrediction => (
                <div
                  key={placePrediction.place_id}
                  className='place-prediction'
                  onClick={() => handlePredictionClick(placePrediction)}
                >
                  <div className='place-prediction-icon-and-text'>
                    <FaMapMarkerAlt style={{ color: '34C759' }} />{' '}
                    <div className='place-prediction-text'>
                      {placePrediction.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='title_and_value'>
          <div className='title'>Dropoff location</div>
          <input
            type='text'
            placeholder='Enter dropoff location'
            value={dropoffPlaceName.placeName}
            onChange={handlePlaceNameChange}
            onFocus={() => handleInputFocus('dropoff')}
          />
          {activeInput === 'dropoff' &&
            appData.placePredictionsList.length > 0 && (
              <div className='place-predictions'>
                {appData.placePredictionsList.map(placePrediction => (
                  <div
                    key={placePrediction.place_id}
                    className='place-prediction'
                    onClick={() => handlePredictionClick(placePrediction)}
                  >
                    <div className='place-prediction-icon-and-text'>
                      <FaMapMarkerAlt style={{ color: '34C759' }} />{' '}
                      <div className='place-prediction-text'>
                        {placePrediction.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>
      <CustomButton text= {isLoading ? 'Loading...' : 'Submit'} onClick={handleSubmit} isDisabled={isLoading} />
      
      <div>
        <div></div>
      </div>
    </div>
  )
}

export default SelectLocation
