import { useLocation, useNavigate } from 'react-router-dom'
import React from 'react'
import '../../../styles/summary-and-payment-page.css'
import { usePlaceTrip } from '../../../providers/place_trip_provider'
import CustomButton from '../../../components/custom_button'
import { useAppData } from '../../../providers/direction_and_price_provider'
import MiniMap from './mini_map'
import { usePaystackPayment } from 'react-paystack'
import { useAuth } from '../../../providers/auth_provider'
import { saveSendTypeToDb } from '../../../providers/place_trip_provider';
import uuid from 'react-uuid';
const SummaryAndPayment = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { vehicles, selectedVehicle, selectVehicle } = usePlaceTrip()
  const [isProcessing, setIsProcessing] = React.useState(false)
  const { currentUser, fetchCurrentUserData } = useAuth()


  const {
    appData,
    updatePickupLocationAddress,
    findUsersPlaces,
    clearPlacePredictionsList,
    getPlaceLatLong,
    obtainPlaceRoute,
    calculatePrice
  } = useAppData()
  const price = calculatePrice(
    location.state.data.legs[0].duration.value,
    220,
    1500
  )
  const formattedPrice = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(price)
  const config = {
    reference: (new Date()).getTime().toString(),
    email: location.state.currentUserData.email,
    amount: price * 100,
    publicKey: 'pk_live_a0cb5a5cc784ddc195e646d6473f48bed719ba86',
};
// pk_live_a0cb5a5cc784ddc195e646d6473f48bed719ba86

const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  try {
      const sendDetails = {
          description: location.state.description,
          collection: location.state.packageType,
          sendersusername: location.state.currentUserData.username,
          pending: 'pending',
          dropOffnumber: location.state.receiverNumber,
          pickupNumber: location.state.senderNumber,
          pickup: location.state.pickupPlaceName.placeName,
          dropOff: location.state.dropoffPlaceName.placeName,
          deliveryId: uuid(),
          estimatedTime: location.state.data.legs[0].duration.text,
          transportationType: selectedVehicle?.name,
          paymentMethod: 'Card',
          lat: location.state.pickupPlaceName.latitude,
          long: location.state.pickupPlaceName.longitude,
          fee: price.toString(),
          status: 'pending',
          encodedPoints: location.state.data.overview_polyline.points.toString(),
      };
      saveSendTypeToDb(sendDetails)
          .then((result) => {
            console.log('Send saved successfully!');
            navigate('/order-successful', { state: { deliveryId: reference } });
          })
          .catch((error) => {
              console.error('Error:', error);
          });
  } catch (error) {
      console.error('Error:', error);
  }
};


  // you can call this function anything
  const onClose = () => {
    console.log('closed')
    alert('Transaction was not completed, window closed.');
  }

  const initializePayment = usePaystackPayment(config);

  return (
    <div className='summary-and-payment-page'>
      {/* <MiniMap
        originLatLong={{
          lat: location.state.pickupPlaceName.latitude,
          lng: location.state.pickupPlaceName.longitude
        }}
        destinationLatLong={{
          lat: location.state.dropoffPlaceName.latitude,
          lng: location.state.dropoffPlaceName.longitude
        }}
      /> */}
      <div className='coupon'>
        <div className='coupon_text'>
            <div className='coupon_text_header'>Coupon Code</div>
            <div className='coupon_text_description'>Enter your coupon code to get a discount on your delivery</div>
        </div>
      <div className='coupon_code_box'>
        <input type='text' placeholder='Enter Coupon Code' />
        <button className='validate-button'>Validate</button>
      </div>
      </div>

      <div className='desired-means-of-transport'>
        <div className='desired-means-of-transport-header'>
          <div class='desired-means-of-transport-header-text'>
            <div className='header-text'>Desired means of transport</div>
            <div className='description'>
              Select the means of transport you would like to use for your trip
            </div>
          </div>
          <div class='desired-means-of-distance-and-duration'>
            <div className='distance-and-duration'>
              <div className='distance'>
                Distance:{' '}
                <span>{location.state.data.legs[0].distance.text}</span>
              </div>
              <div className='duration'>
                Duration:{' '}
                <span>{location.state.data.legs[0].duration.text}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='divider'></div>
        <div className='desired-means-of-transport-body'>
          <div className='desired-means-of-transport-body-vehicles'>
            {vehicles.map(vehicle => (
              <div
                className={`vehicle ${
                  selectedVehicle?.name === vehicle.name ? 'selected' : ''
                }`}
                key={vehicle.image}
                onClick={() => selectVehicle(vehicle)}
              >
                <div className='vehicle-image'>
                  <img src={vehicle.image} alt='vehicle' />
                </div>
                <div className='vehicle-name'>{vehicle.name}</div>
              </div>
            ))}
          </div>
          <div className='divider'></div>
          <div className='desired-means-of-transport-body-summary'>
            <div className='summary-text'>Delivery Summary</div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Pickup Address</div>
              <div className='value'>
                {location.state.pickupPlaceName.placeName}
              </div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Dropoff Address</div>
              <div className='value'>
                {location.state.dropoffPlaceName.placeName}
              </div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Package Type</div>
              <div className='value'>{location.state.packageType}</div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Package Description</div>
              <div className='value'>{location.state.description}</div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Sender's Number</div>
              <div className='value'>{location.state.senderNumber}</div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Receiver's Number</div>
              <div className='value'>{location.state.receiverNumber}</div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
                <div className='title'>Selected Vehicle</div>
                <div className='value'>
                    {selectedVehicle?.name || 'No vehicle selected'}
                </div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Distance</div>
              <div className='value'>
                {location.state.data.legs[0].distance.text}
              </div>
            </div>
            <div className='divider'></div>
            
            <div className='summary-title-and-value'>
              <div className='title'>Duration</div>
              <div className='value'>
                {location.state.data.legs[0].duration.text}
              </div>
            </div>
            <div className='divider'></div>
            <div className='summary-title-and-value'>
              <div className='title'>Delivery Cost</div>
              <div className='value'>{formattedPrice}</div>
            </div>
          </div>
          <CustomButton
            text={'Proceed to Payment'}
            onClick={() => {
                //if no vehicle is selected, alert the user
                if (!selectedVehicle) {
                    alert('Please select a vehicle');
                    return;
                }
                initializePayment(onSuccess, onClose);
            }
            }
            isDisabled={false}
          />
        </div>
      </div>
    </div>
  )
}

export default SummaryAndPayment
