import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import UserTypeCard from "./components/usertypecard";
import { useNavigate } from "react-router-dom";
import DialogContainer from "./components/dialog_container";

const UserType = () => {
    const history = useNavigate();
    const [showDialog, setShowDialog] = useState(false);

    const handleRiderClick = () => {
        setShowDialog(true);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleCustomerSignup = () => {
        history("/login");
    };

    return (
        <div className="user_type">
            <p className="title">Choose your account type</p>
            <div className="user_type_cards">
                <UserTypeCard type="customer" onClick={handleCustomerSignup} />
                <UserTypeCard type="rider" onClick={handleRiderClick} />
            </div>
            {showDialog && (
                <div className="dialog">
                    <DialogContainer onClose={handleDialogClose} />
                </div>
            )}
        </div>
    );
};

export default UserType;