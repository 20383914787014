import React from 'react'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import '../../../styles/send_package_detail.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import CustomButton from '../../../components/custom_button'
import { useNavigate } from 'react-router-dom'

const SendPackageDetails = ({}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const object = location.state
  const [packageType, setPackageType] = React.useState(null);
  const [senderNumber, setSenderNumber] = useState()
  const [receiverNumber, setReceiverNumber] = useState()
  const [image, setImage] = useState()
  const [description, setDescription] = useState()

  if (!object) {
    // Handle the case where object is not passed
    return null
  }
  //move to next page
  const moveToNextPage = () => {
    //check if all fields are filled
    console.log(
      'packageType',
      packageType,
      'senderNumber',
      senderNumber,
      'receiverNumber',
      receiverNumber
    )
    if (packageType === '' || senderNumber === '' || receiverNumber === '') {
      alert('Please fill all fields')
    } else {
      //move to next page
      navigate('/select_location', {
        state: { packageType, senderNumber, receiverNumber, image, description }
      })
    }
  }

  return (
    <div className='send-package-detail-page'>
      <div className='send-package-image-box'>
        <img src={object.image} alt='package' />
      </div>
      <div className='send-package-detail-box'>
        <div className='title_and_value'>
          <div className='title'>
            Select the type of package you want to send
          </div>
          {/* //dropdown for package type */}
          <select
            className='package-type-select'
            value={packageType}
            onChange={e => setPackageType(e.target.value)}
          >
            <option value={null}>Select a package type</option>
            {object.dropDownItems.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className='title_and_value'>
          <div className='title'>Sender's phone number</div>
          <PhoneInput
            className='phone-number'
            placeholder='Enter phone number'
            value={senderNumber}
            onChange={setSenderNumber}
          />
        </div>
        <div className='title_and_value'>
          <div className='title'>Receiver's phone number</div>
          <PhoneInput
            className='phone-number'
            placeholder='Enter phone number'
            value={receiverNumber}
            onChange={setReceiverNumber}
          />
        </div>
        <div className='title_and_value'>
          <div className='title'>Image(Optional)</div>
          <input type='file' className='image' />
        </div>
        <div className='title_and_value'>
          <div className='title'>Description</div>
          <input
            type='text'
            className='description'
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <CustomButton
          text='Continue'
          onClick={() => {
            moveToNextPage()
          }}
          isDisabled={false}
        />
      </div>
    </div>
  )
}

export default SendPackageDetails
