import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { addDoc, setDoc, doc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';


import { auth } from '../config/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
const AuthContext = createContext();


export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const registerUser = async (username, email, phoneNumber, homeAddress, ageRange, gender, password) => {
    try {
      const isEmailAlreadyExists = await checkIfEmailAlreadyExists(email);
      if (!isEmailAlreadyExists) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        setCurrentUser(user);
        const db = getFirestore();
        const encodedEmail = encodeURIComponent(email); // Encode the email
        const userDocRef = doc(db, 'users', encodedEmail);
        await setDoc(userDocRef, {
          "username": username,
          "email": email,
          "phone-number": phoneNumber,
          "Home-Address": homeAddress,
          "Age Range": ageRange,
          "Gender": gender,
          "photo": null, // Assuming you want to set photo to null
          "Work-address": null, // Assuming you want to set workAddress to null
          "uid": user.uid, // Assuming you want to store the user's uid
        });
      } else {
        throw new Error('Email is already registered');
      }
    } catch (error) {
      console.error('Registration error:', error.message);
      throw error;
    }
  };  
  const loginUser = async (email, password) => {
    try {
      const isEmailAlreadyExists = await checkIfEmailAlreadyExists(email);
      if (!isEmailAlreadyExists) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        setCurrentUser(user);
      } else {
        throw new Error('This email is already registered as a rider');
      }
    } catch (error) {
      console.error('Login error:', error.message);
      throw error;
    }
  }



  const checkIfEmailAlreadyExists = async (email) => {
    const db = getFirestore();
    const q = query(collection(db, 'Drivers'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty ? false : true;
  };

  const signOut = async () => {
    // Implement your signOut function here
  };

  const fetchUserData = async (email) => {
    try {
    console.log(email);
      const db = getFirestore();
      const userRef = collection(db, 'users');
      const q = query(userRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setUserData(userData);
        console.log(userData);
      } else {
        throw new Error('User data not found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  const fetchCurrentUserData = async () => {
    try {
      const db = getFirestore();
      const userRef = collection(db, 'users');
      const q = query(userRef, where('email', '==', currentUser.email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        return userData;
      } else {
        throw new Error('User data not found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  const sendPasswordRestLink = async (email) => {
    // Implement your sendPasswordRestLink function here
    const isEmailAlreadyExists = await checkIfEmailAlreadyExists(email);
    if (!isEmailAlreadyExists) {
      //send password reset link
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        throw new Error('Error sending password reset link:', error.message);
      }  
    }else{
      throw new Error('This email is registered as a rider, please use another email');
    }
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchUserData(user.email);
      } else {
        setUserData(null);
      }
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    registerUser,
    loginUser,
    signOut,
    userData,
    fetchUserData,
    fetchCurrentUserData,
    sendPasswordRestLink,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;